.ant-picker .ant-picker-input >input {
	background-color: transparent;
	color: #fff;
	font-size: 13px
}

.ant-picker .ant-picker-input input::placeholder {
	@apply text-white
}
.ant-picker .ant-picker-input .ant-picker-suffix {
	@apply text-white
}

.ant-btn.ant-btn-sm {
	@apply bg-blue-500
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	padding: 0;
	background-color: transparent;
	border: hidden;
	font-size: 13px;
	color: #fff;
}

.ant-select .ant-select-arrow {
	@apply text-white
}