.contentCent:nth-child(4){
    display: block !important;
}
.advantages{
    display:flex;
    /* margin: 50px 0; */
    justify-content:center;
    align-items:center;
    width: 100%;
    margin-top: -50px;
}
.advantages .container{
    /* display: grid;
    grid-template-columns: repeat(1fr, 1fr, 1fr);
    grid-template-rows: repeat(1fr, 1fr); */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}
.list-item{
    flex-basis: 30%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}
.list-item_icon{
    padding: 20px;
    font-size: 3rem;
    color: #46b7de;
    border: 1px solid rgba(128, 128, 128, 0.436);
    border-radius: 50%;
}

@media(max-width: 848px){
    .list-item{
        flex-basis: 40%;
    }
}
@media(max-width: 480px){
    .list-item{
        flex-basis: 90%;
    }
}