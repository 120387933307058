@font-face {
  font-family: Icons;
  src: url(../fonts/open-iconic/open-iconic.woff);
  src: url(../fonts/open-iconic/open-iconic.woff)
  url(../fonts/open-iconic/open-iconic.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
.oi {
  position: relative;
  top: 1px;
  display: inline-block;
  speak: none;
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.oi:empty:before {
  width: 1em;
  text-align: center;
  box-sizing: content-box;
}
.oi-menu:before {
  content: "\e09b";
}
