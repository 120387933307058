@media screen and (max-width: 600px) {
	h1 {
		@apply text-[24px]
	}
}
.blog-wrap{
	display: flex;
	flex-direction: column;
	padding: 20px;
	border: 2px solid #46b7de;
	border-top-left-radius: 12px;
	border-bottom-right-radius: 12px;
	background-color: white;
}
.blog1{
	display: flex;
	flex-direction: row;
	width: 100%;
}
.blog1 img{
	width: 50%;
	height: 450px;
	border-radius: 20px;
}
.blog1 p{
	flex-basis: 40%;
	margin-left: 50px;
	font-size: 14px;
}
.blog2{
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 50px;
}
.blog2 img{
	width: 50%;
	height: 450px;
	border-radius: 20px;
}
.blog2 p{
	flex-basis: 50%;
	margin-right: 20px;
	font-size: 14px;
}

@media(max-width:1080px){
	.blog1,.blog2{
		flex-direction: column;
		align-items: center;
		text-align: center;
		font-size: 15px;
	}
	.blog1 img,.blog2 img{
		width: 80%;
	}
	.blog2 img{
		order: 1;
	}
	.blog2 p{
		order: 2;
	}
	.blog1 p,.blog2 p{
		margin-left: 0;
		margin-right: 0;
		margin-top: 10px;
	}
}
@media(max-width:768px){
	.blog-wrap{
		padding: 20px;
	}
	.blog1 img,.blog2 img{
		width: 100%;
	}
}
@media(max-width:480px){
	.blog-wrap{
		padding: 20px;
	}
	.blog-title{
		font-size: 25px;
	}
	.blog1 img,.blog2 img{
		width: 100%;
		height: 300px;
	}
}
