.slick-dots {
	@apply absolute bottom-[30px];
}

.slick-dots li {
	@apply text-2xl;
	width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 50%;
  background: #e6e6e6;
	opacity: .7;
  position: relative; 
}
 
.slick-dots li.slick-active {
	background: #fff;
	opacity: 1;
}

.slick-dots li button:before
{
    font-family: 'slick';
    position: absolute;
    top: -2px;
    left: -2px;
    right: 0;
    bottom: 0;
    width: 14px;
    height: 14px;
    content: '';
    border: 1px solid #e6e6e6;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;				
}

.slick-slider .slick-next {
	display: none!important;
}
.home-slider h1, .home-slider h3{
  color: white !important;
}