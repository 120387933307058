@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~@flaticon/flaticon-uicons/css/all/all";


:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }


.container {
	@apply w-[80%] mx-auto
}
section {
	margin: 0;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

 figcaption, figure, footer, header, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

body {
	max-width: 100%;
	overflow-x: hidden;
}


.collapse {
	visibility: visible;
}

p {
  margin-top: 0;
  margin-bottom: 1rem; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }


ul {
  margin-top: 0;
  margin-bottom: 1rem; }


ul ul {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

b {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }


code {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

output {
  display: inline-block; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }



.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; 
}

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

 .col, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-12, .col-md, .col-lg-3, .col-lg-4, .col-lg-6, .col-lg-8, .col-xl-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; } }

@media (min-width: 992px) {
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 1200px) {
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.fade {
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; 
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:first-child {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  .dropdown-item:last-child {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    /* -ms-flex-align: center; */
    /* align-items: center; */
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

@media screen and (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; 
	} 
	.navbar > .container .dropdown {
		@apply top-0 right-[5%]
	}
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    -ms-transform: translate(0, -50px);
    transform: translate(0, -50px); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    -o-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media screen and (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.d-block {
  display: block !important; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  -ms-grid-row-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; } }

.position-static {
  position: static !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.w-100 {
  width: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.p-2 {
  padding: 0.5rem !important; }


.py-2 {
  padding-top: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }


.px-3 {
  padding-right: 1rem !important; }


.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }


.py-4 {
  padding-top: 1.5rem !important; }


.px-4 {
  padding-right: 1.5rem !important; }


.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5 {
  padding-top: 3rem !important; }


.px-5 {
  padding-right: 3rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }


.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.ml-auto {
  margin-left: auto !important; }

@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  
  .px-md-0 {
    padding-right: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  
  .px-md-5 {
    padding-right: 3rem !important; }
  
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; } }

.text-center {
  text-align: center !important; }

@media (min-width: 768px) {
  .text-md-right {
    text-align: right !important; } }

.font-weight-bold {
  font-weight: 700 !important; }

.visible {
  visibility: visible !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; } }

body {
  font-family: "Poppins", Arial, sans-serif;
  background: #fff;
  font-size: 15px;
  line-height: 1.8;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6); }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  color: #46b7de; }
  a:hover, a:focus {
    text-decoration: none;
    color: #46b7de;
    outline: none !important; }

h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400; }

.topper {
  font-size: 15px;
  width: 100%; }
  .topper .icon {
    width: 50px;
    height: 50px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%; }
    .topper .icon span {
      color: #46b7de; }
  .topper .text {
    width: calc(100% - 50px); }
    .topper .text p {
      margin-bottom: 0;
      font-size: 12px; }
    .topper .text .con {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 300; }
      .topper .text .con span {
        font-weight: 600;
        font-size: 13px;
        color: #000000; }
        .topper .text .con span:first-child {
          color: #46b7de; }
    .topper .text .hr span {
      color: #000000;
      font-weight: 600;
      font-size: 13px; }
    .topper .text .time span:last-child {
      font-weight: 600;
      color: #46b7de; }

.ftco-navbar-light {
  background: transparent !important;
  z-index: 3;
  padding: 0; }
  @media (max-width: 991.98px) {
    .ftco-navbar-light {
      background: #000000 !important;
      position: relative;
      top: 0;
      padding: 10px 15px; } }
  .ftco-navbar-light .navbar-brand {
    color: #000000; }
    .ftco-navbar-light .navbar-brand:hover, .ftco-navbar-light .navbar-brand:focus {
      color: #000000; }
    @media (max-width: 991.98px) {
      .ftco-navbar-light .navbar-brand {
        color: #fff; } }
  @media (max-width: 991.98px) {
    .ftco-navbar-light .navbar-nav {
      padding-bottom: 10px; } }
  .ftco-navbar-light .navbar-nav > .nav-item > .nav-link {
    font-size: 15px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 400;
    color: #000000;
    letter-spacing: 1px;
    text-transform: uppercase;
    opacity: 1 !important; }
    .ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover {
      color: #000000; }
    @media (max-width: 991.98px) {
      .ftco-navbar-light .navbar-nav > .nav-item > .nav-link {
        padding-left: 0;
        padding-right: 0;
        padding-top: .9rem;
        padding-bottom: .9rem;
        color: rgba(255, 255, 255, 0.7); }
        .ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover {
          color: #fff; } }
  .ftco-navbar-light .navbar-nav > .nav-item .dropdown-menu {
    border: none;
    background: #fff;
    -webkit-box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
    -moz-box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
    box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41); }
    .ftco-navbar-light .navbar-nav > .nav-item .dropdown-menu .dropdown-item {
      font-size: 14px; }
      .ftco-navbar-light .navbar-nav > .nav-item .dropdown-menu .dropdown-item:hover, .ftco-navbar-light .navbar-nav > .nav-item .dropdown-menu .dropdown-item:focus {
        background: transparent;
        color: #000000; }
  .ftco-navbar-light .navbar-nav > .nav-item.active > a {
    color: #46b7de; }
    @media (max-width: 991.98px) {
      .ftco-navbar-light .navbar-nav > .nav-item.active > a {
        color: #fff; } }
  .ftco-navbar-light .navbar-toggler {
    border: none;
    color: rgba(255, 255, 255, 0.5) !important;
    cursor: pointer;
    padding-right: 0;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: .1em; }
    .ftco-navbar-light .navbar-toggler:focus {
      outline: none !important; }
  .ftco-navbar-light.scrolled {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    margin-top: -130px;
    background: #fff !important;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); }
    .ftco-navbar-light.scrolled .nav-item.active > a {
      color: #46b7de !important; }
    @media (max-width: 991.98px) {
      .ftco-navbar-light.scrolled .navbar-nav {
        background: none;
        border-radius: 0px;
        padding-left: 0rem !important;
        padding-right: 0rem !important; } }
    @media (max-width: 767.98px) {
      .ftco-navbar-light.scrolled .navbar-nav {
        background: none;
        padding-left: 0 !important;
        padding-right: 0 !important; } }
    .ftco-navbar-light.scrolled .navbar-toggler {
      border: none;
      color: rgba(0, 0, 0, 0.5) !important;
      border-color: rgba(0, 0, 0, 0.5) !important;
      cursor: pointer;
      padding-right: 0;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: .1em; }
    .ftco-navbar-light.scrolled .nav-link {
      padding-top: 0.9rem !important;
      padding-bottom: 0.9rem !important;
      color: #000000 !important; }
      .ftco-navbar-light.scrolled .nav-link.active {
        color: #46b7de !important; }
    .ftco-navbar-light.scrolled.awake {
      margin-top: 0px;
      -webkit-transition: .3s all ease-out;
      -o-transition: .3s all ease-out;
      transition: .3s all ease-out; }
    .ftco-navbar-light.scrolled.sleep {
      -webkit-transition: .3s all ease-out;
      -o-transition: .3s all ease-out;
      transition: .3s all ease-out; }
    .ftco-navbar-light.scrolled .navbar-brand {
      color: #000000; }

.navbar-brand {
  font-weight: 700;
  font-size: 34px;
  padding: 0;
  line-height: 1;
  color: #000000; }
  .navbar-brand span {
    font-size: 14px;
    font-weight: 500;
    display: block;
    color: rgba(0, 0, 0, 0.2);
    text-align: left; }

.hero-wrap {
  width: 100%;
  height: 100%;
  position: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative; }
  @media (max-width: 991.98px) {
    .hero-wrap {
      background-position: top center !important; } }
  .hero-wrap .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    opacity: .5;
    background: #46b7de; }
  .hero-wrap.hero-wrap-2 {
    height: 300px; }
    .hero-wrap.hero-wrap-2 .slider-text {
      height: 300px; }
      .hero-wrap.hero-wrap-2 .slider-text .bread {
        font-weight: 700;
        color: #fff; }
      .hero-wrap.hero-wrap-2 .slider-text .breadcrumbs {
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 1px; }
        .hero-wrap.hero-wrap-2 .slider-text .breadcrumbs span {
          color: #fff;
          border-bottom: 2px solid rgba(255, 255, 255, 0.1); }
          .hero-wrap.hero-wrap-2 .slider-text .breadcrumbs span a {
            color: #fff; }

.owl-carousel {
  position: relative; }
  .owl-carousel .owl-item {
    opacity: .4; }
    .owl-carousel .owl-item.active {
      opacity: 1; }
  .owl-carousel .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%; }
    .owl-carousel .owl-nav .owl-prev,
    .owl-carousel .owl-nav .owl-next {
      position: absolute;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-top: -10px;
      color: #46b7de !important;
      -moz-transition: all 0.7s ease;
      -o-transition: all 0.7s ease;
      -webkit-transition: all 0.7s ease;
      -ms-transition: all 0.7s ease;
      transition: all 0.7s ease;
      opacity: 0; }
      .owl-carousel .owl-nav .owl-prev span:before,
      .owl-carousel .owl-nav .owl-next span:before {
        font-size: 30px; }
    .owl-carousel .owl-nav .owl-prev {
      left: 0; }
    .owl-carousel .owl-nav .owl-next {
      right: 0; }
  .owl-carousel .owl-dots {
    text-align: center; }
    .owl-carousel .owl-dots .owl-dot {
      width: 10px;
      height: 10px;
      margin: 5px;
      border-radius: 50%;
      background: #e6e6e6;
      position: relative; }
      .owl-carousel .owl-dots .owl-dot:after {
        position: absolute;
        top: -2px;
        left: -2px;
        right: 0;
        bottom: 0;
        width: 14px;
        height: 14px;
        content: '';
        border: 1px solid #e6e6e6;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
      .owl-carousel .owl-dots .owl-dot:hover, .owl-carousel .owl-dots .owl-dot:focus {
        outline: none !important; }
      .owl-carousel .owl-dots .owl-dot.active {
        background: #b3b3b3; }
  .owl-carousel:hover .owl-nav .owl-prev,
  .owl-carousel:hover .owl-nav .owl-next {
    opacity: 1; }
  .owl-carousel:hover .owl-nav .owl-prev {
    left: -25px; }
  .owl-carousel:hover .owl-nav .owl-next {
    right: -25px; }
  .owl-carousel.home-slider {
    position: relative;
    height: 600px;
    z-index: 0; }
    .owl-carousel.home-slider .slider-item {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      height: 600px;
      position: relative;
      z-index: 0; }
      @media (max-width: 1199.98px) {
        .owl-carousel.home-slider .slider-item {
          background-position: center center !important; } }
      .owl-carousel.home-slider .slider-item .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000000;
        opacity: 0; }
      .owl-carousel.home-slider .slider-item .slider-text {
        height: 600px; }
        @media (max-width: 767.98px) {
          .owl-carousel.home-slider .slider-item .slider-text {
            text-align: center; } }
        .owl-carousel.home-slider .slider-item .slider-text .subheading {
          color: #000000;
          font-weight: 700; }
        .owl-carousel.home-slider .slider-item .slider-text .text {
          position: relative; }
        .owl-carousel.home-slider .slider-item .slider-text h1 {
          font-size: 50px;
          color: #000000;
          line-height: 1.2;
          font-weight: 300; }
          .owl-carousel.home-slider .slider-item .slider-text h1 span {
            display: block;
            color: #000000; }
          @media (max-width: 991.98px) {
            .owl-carousel.home-slider .slider-item .slider-text h1 {
              font-size: 30px; } }
        .owl-carousel.home-slider .slider-item .slider-text .subheading {
          font-size: 18px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8); }
        .owl-carousel.home-slider .slider-item .slider-text p {
          font-size: 18px;
          line-height: 1.5;
          font-weight: 300;
          color: rgba(0, 0, 0, 0.8); }
        .owl-carousel.home-slider .slider-item .slider-text .breadcrumbs {
          text-transform: uppercase;
          font-size: 13px;
          letter-spacing: 1px; }
          .owl-carousel.home-slider .slider-item .slider-text .breadcrumbs span {
            border-bottom: 2px solid rgba(255, 255, 255, 0.1); }
            .owl-carousel.home-slider .slider-item .slider-text .breadcrumbs span a {
              color: #fff; }
    .owl-carousel.home-slider .owl-nav {
      position: absolute;
      top: 100%;
      width: 100%; }
      .owl-carousel.home-slider .owl-nav .owl-prev,
      .owl-carousel.home-slider .owl-nav .owl-next {
        position: absolute;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-top: -10px;
        outline: none !important;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
        opacity: 0; }
        .owl-carousel.home-slider .owl-nav .owl-prev span:before,
        .owl-carousel.home-slider .owl-nav .owl-next span:before {
          font-size: 30px;
          color: rgba(255, 255, 255, 0.5);
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          transition: all 0.3s ease; }
        .owl-carousel.home-slider .owl-nav .owl-prev:hover span:before, .owl-carousel.home-slider .owl-nav .owl-prev:focus span:before,
        .owl-carousel.home-slider .owl-nav .owl-next:hover span:before,
        .owl-carousel.home-slider .owl-nav .owl-next:focus span:before {
          color: #fff; }
      .owl-carousel.home-slider .owl-nav .owl-prev {
        left: 50%;
        margin-left: -80px; }
      .owl-carousel.home-slider .owl-nav .owl-next {
        right: 50%;
        margin-right: -80px; }
    .owl-carousel.home-slider:hover .owl-nav .owl-prev,
    .owl-carousel.home-slider:hover .owl-nav .owl-next {
      opacity: 1; }
    .owl-carousel.home-slider:hover .owl-nav .owl-prev {
      left: 50%;
      margin-left: -80px; }
    .owl-carousel.home-slider:hover .owl-nav .owl-next {
      right: 50%;
      margin-right: -80px; }
    .owl-carousel.home-slider .owl-dots {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 60px;
      width: 100%;
      text-align: center; }
      @media (max-width: 767.98px) {
        .owl-carousel.home-slider .owl-dots {
          bottom: 5px; } }
      .owl-carousel.home-slider .owl-dots .owl-dot {
        width: 10px;
        height: 10px;
        margin: 5px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.4); }
        .owl-carousel.home-slider .owl-dots .owl-dot.active {
          background: #fff; }


.bg-light {
  background: #fafafa !important; }

.bg-white {
  background: #fff !important; }

.btn {
  cursor: pointer;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  border-radius: 40px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }
  .btn:hover, .btn:active, .btn:focus {
    outline: none; }
  .btn.btn-primary {
    background: #46b7de;
    border: 1px solid #46b7de;
    color: #fff; }
    .btn.btn-primary:hover {
      border: 1px solid #46b7de;
      background: transparent;
      color: #46b7de; }
  .btn.btn-secondary {
    background: #a8c337;
    border: 1px solid #a8c337;
    color: #fff; }
    .btn.btn-secondary:hover, .btn.btn-secondary:focus, .btn.btn-secondary:active {
      border: 1px solid #a8c337;
      color: #fff; }

.btn-custom {
  font-weight: 500;
  color: #24a1cd;
  border-bottom: 4px solid #57bee1; }

.img-2 {
  background-position: top center !important; }
  @media (max-width: 767.98px) {
    .img-2 {
      height: 300px;
      margin-bottom: 40px; } }

.wrap-about {
  position: relative; }
  @media (min-width: 768px) {
    .wrap-about {
      padding-left: 6em; } }
  .wrap-about .founder .img {
    width: 70px;
    height: 70px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%; }
  .wrap-about .founder .text {
    width: calc(100%-70px); }
  .wrap-about .founder h3 {
    font-size: 24px; }

.ftco-services .services {
  display: block;
  width: 100%;
  position: relative;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .ftco-services .services .img {
    height: 250px; }
  .ftco-services .services .icon {
    line-height: 1.3;
    position: relative;
    width: 80px;
    height: 80px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin: 0 auto;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%; }
    .ftco-services .services .icon span {
      font-size: 50px;
      color: #46b7de; }
  .ftco-services .services .media-body h3 {
    font-size: 18px;
    color: #000000; }

.intro {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center; }
  @media (max-width: 991.98px) {
    .intro {
      background-position: top center !important; } }

.ftco-intro {
  padding: 4em 0;
  width: 100%;
  height: 100%;
  position: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  color: rgba(255, 255, 255, 0.8); }
  @media (max-width: 991.98px) {
    .ftco-intro {
      background-position: center center !important; } }
  .ftco-intro h2 {
    color: #fff;
    font-size: 36px; }
  .ftco-intro .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    opacity: .8;
    background: #46b7de; }

.staff {
  overflow: hidden;
  margin-bottom: 30px;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .staff .img-wrap {
    height: 300px; }
  .staff .img {
    width: 100%;
    display: block;
    background-position: top center;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .staff .text {
    position: relative;
    background: #fff;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .staff .text h3 {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 0;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    .staff .text .position {
      text-transform: uppercase;
      color: #b3b3b3;
      display: block;
      letter-spacing: 1px; }
    .staff .text .faded {
      opacity: 1; }
  .staff .ftco-social {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    padding: 15px 10px;
    background: transparent;
    opacity: 1;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .staff .ftco-social li {
      width: 30px;
      height: 30px;
      position: relative; }
      .staff .ftco-social li a {
        color: #a8c337;
        width: 30px;
        height: 30px;
        background: #fff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
  .staff:hover .ftco-social, .staff:focus .ftco-social {
    opacity: 1;
    top: -60px; }
  .staff:hover .img, .staff:focus .img {
    margin-top: -10px; }
  .staff:hover .text h3, .staff:focus .text h3 {
    color: #46b7de; }

.ftco-social {
  padding: 0; }
  .ftco-social li {
    list-style: none;
    margin-right: 10px;
    display: inline-block; }

.pricing-entry {
  border: 1px solid #c8eaf5;
  position: relative;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  @media (max-width: 767.98px) {
    .pricing-entry {
      margin-bottom: 4em; } }
  .pricing-entry > div {
    padding: 20px; }
    .pricing-entry > div p {
      margin-bottom: 0; }
  .pricing-entry h3 {
    font-size: 16px;
    font-weight: 400; }
  .pricing-entry p .price {
    font-weight: 300;
    font-size: 28px;
    color: #46b7de;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .pricing-entry p .per {
    font-size: 12px; }
  .pricing-entry ul {
    margin: 0;
    padding: 0; }
    .pricing-entry ul li {
      list-style: none;
      padding: 10px 20px; }
      .pricing-entry ul li:nth-child(odd) {
        background: #fafafa; }
  .pricing-entry .button {
    position: absolute;
    bottom: -26px;
    left: 0;
    right: 0;
    margin-bottom: 0;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .pricing-entry .button .btn {
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      border-radius: 30px;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease; }
  .pricing-entry:hover .btn, .pricing-entry:focus .btn {
    background: #a8c337 !important;
    border: 1px solid #a8c337 !important;
    color: #fff !important; }
  .pricing-entry:hover .price, .pricing-entry:focus .price {
    color: #a8c337; }

.block-20 {
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 275px;
  position: relative;
  display: block; }

@media (min-width: 768px) {
  .blog-entry {
    margin-bottom: 30px; } }

@media (max-width: 767.98px) {
  .blog-entry {
    margin-bottom: 30px; } }

.blog-entry .text {
  position: relative;
  border-top: 0;
  border-radius: 2px; }
  .blog-entry .text .heading {
    font-size: 20px;
    margin-bottom: 16px; }
    .blog-entry .text .heading a {
      color: #000000; }
      .blog-entry .text .heading a:hover, .blog-entry .text .heading a:focus, .blog-entry .text .heading a:active {
        color: #46b7de; }
  .blog-entry .text .meta-chat {
    color: #b3b3b3; }
  .blog-entry .text .read {
    color: #000000;
    font-size: 14px; }

.blog-entry .meta-date {
  display: inline-block;
  background: #46b7de; }
  .blog-entry .meta-date span {
    display: block;
    color: #fff; }
  .blog-entry .meta-date .day {
    font-weight: 300;
    font-size: 30px; }
  .blog-entry .meta-date .mos, .blog-entry .meta-date .yr {
    font-size: 13px; }

.blog-entry .meta > div {
  display: block;
  margin-right: 5px;
  margin-bottom: 0px;
  font-size: 15px; }
  .blog-entry .meta > div a {
    color: #b3b3b3;
    font-size: 13px; }
    .blog-entry .meta > div a:hover {
      color: #cccccc; }

.block-27 ul {
  padding: 0;
  margin: 0; }
  .block-27 ul li {
    display: inline-block;
    margin-bottom: 4px;
    font-weight: 400; }
    .block-27 ul li a, .block-27 ul li span {
      border: 1px solid #46b7de;
      text-align: center;
      display: inline-block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%; }
    .block-27 ul li.active a, .block-27 ul li.active span {
      background: #46b7de;
      color: #fff;
      border: 1px solid transparent; }

.form-control {
  height: 52px !important;
  background: #fff !important;
  color: #000000 !important;
  font-size: 18px;
  border-radius: 0px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.1); }
  .form-control:focus, .form-control:active {
    border-color: #000000; }

textarea.form-control {
  height: inherit !important; }

.ftco-animate {
  opacity: 0;
  visibility: hidden; }

.about-author .desc h3 {
  font-size: 24px; }

.ftco-section {
  padding: 7em 0;
  position: relative; }

.ftco-no-pt {
  padding-top: 0; }

.ftco-no-pb {
  padding-bottom: 0; }

.ftco-bg-dark {
  background: #3c312e; }

.ftco-footer {
  font-size: 14px;
  background: #46b7de;
  padding: 6em 0; }
  .ftco-footer .ftco-footer-widget {
    width: 100%;
    display: block; }
    .ftco-footer .ftco-footer-widget h2 {
      font-weight: normal;
      color: #fff;
      margin-bottom: 40px;
      font-size: 20px;
      font-weight: 400; }
    .ftco-footer .ftco-footer-widget .logo {
      color: #fff; }
    .ftco-footer .ftco-footer-widget ul li a span {
      color: #fff; }
    .ftco-footer .ftco-footer-widget .btn-primary {
      background: #fff !important;
      border: 2px solid #fff !important; }
      .ftco-footer .ftco-footer-widget .btn-primary:hover {
        background: #fff;
        border: 2px solid #fff !important; }
  .ftco-footer p {
    color: rgba(255, 255, 255, 0.7); }
  .ftco-footer a {
    color: rgba(255, 255, 255, 0.7); }
    .ftco-footer a:hover {
      color: #fff; }
  .ftco-footer .ftco-heading-2 {
    font-size: 17px;
    font-weight: 400;
    color: #000000; }
  .ftco-footer .categories li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
    .ftco-footer .categories li a span {
      color: rgba(255, 255, 255, 0.5) !important; }
  .ftco-footer .subscribe-form .form-group {
    position: relative;
    margin-bottom: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0; }
    .ftco-footer .subscribe-form .form-group input {
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      border-radius: 2px;
      width: 100%;
      background: rgba(255, 255, 255, 0.1) !important;
      border: none;
      color: rgba(255, 255, 255, 0.7) !important;
      font-size: 16px; }
      .ftco-footer .subscribe-form .form-group input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.7) !important; }
      .ftco-footer .subscribe-form .form-group input::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.7) !important; }
      .ftco-footer .subscribe-form .form-group input:-ms-input-placeholder {
        /* IE 0+ */
        color: rgba(255, 255, 255, 0.7) !important; }
      .ftco-footer .subscribe-form .form-group input:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(255, 255, 255, 0.7) !important; }
    .ftco-footer .subscribe-form .form-group .submit {
      color: #fff !important;
      font-size: 16px;
      background: #a8c337 !important;
      border: none !important;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      border-radius: 2px;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .ftco-footer .subscribe-form .form-group .submit:hover {
        cursor: pointer;
        background: #a8c337 !important; }
  .ftco-footer .subscribe-form .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.8); }
  .ftco-footer .block-21 .text .heading {
    font-weight: 400;
    font-size: 16px; }
    .ftco-footer .block-21 .text .heading a {
      color: rgba(255, 255, 255, 0.9); }
      .ftco-footer .block-21 .text .heading a:hover, .ftco-footer .block-21 .text .heading a:active, .ftco-footer .block-21 .text .heading a:focus {
        color: #fff; }
  .ftco-footer .block-21 .text .meta > div {
    display: inline-block;
    font-size: 12px;
    margin-right: 5px; }
    .ftco-footer .block-21 .text .meta > div a {
      color: rgba(255, 255, 255, 0.5); }
  .ftco-footer .open-hours {
    color: #fff;
    font-size: 16px; }
    .ftco-footer .open-hours span {
      color: #fff; }

.ftco-footer-social li {
  list-style: none;
  margin: 0 10px 0 0;
  display: inline-block; }
  .ftco-footer-social li a {
    height: 40px;
    width: 40px;
    display: block;
    float: left;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    position: relative; }
    .ftco-footer-social li a span {
      position: absolute;
      font-size: 20px;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .ftco-footer-social li a:hover {
      color: #fff; }

#map {
  width: 100%; }
  @media (max-width: 767.98px) {
    #map {
      height: 300px; } }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(70, 183, 222, 0.4);
    -webkit-box-shadow: 0 0 0 0 rgba(70, 183, 222, 0.4);
    box-shadow: 0 0 0 0 rgba(70, 183, 222, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 30px rgba(70, 183, 222, 0);
    -webkit-box-shadow: 0 0 0 30px rgba(70, 183, 222, 0);
    box-shadow: 0 0 0 30px rgba(70, 183, 222, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(70, 183, 222, 0);
    -webkit-box-shadow: 0 0 0 0 rgba(70, 183, 222, 0);
    box-shadow: 0 0 0 0 rgba(70, 183, 222, 0); } }

.heading-section .subheading {
  font-weight: 400;
  font-size: 16px;
  display: block;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #000000;
  position: relative; }

.heading-section h2 {
  font-size: 34px;
  font-weight: 400; }
  @media (max-width: 767.98px) {
    .heading-section h2 {
      font-size: 28px; } }


.img,
.blog-img,
.user-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.testimony-section {
  position: relative; }
  .testimony-section .owl-carousel {
    margin: 0; }
  .testimony-section .owl-carousel .owl-stage-outer {
    padding-top: 0;
    padding-bottom: 2em;
    position: relative; }
  .testimony-section .owl-nav {
    position: absolute;
    top: 100%;
    width: 100%; }
    .testimony-section .owl-nav .owl-prev,
    .testimony-section .owl-nav .owl-next {
      position: absolute;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-top: -10px;
      outline: none !important;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      opacity: 0; }
      .testimony-section .owl-nav .owl-prev span:before,
      .testimony-section .owl-nav .owl-next span:before {
        font-size: 30px;
        color: rgba(0, 0, 0, 0.2);
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease; }
      .testimony-section .owl-nav .owl-prev:hover span:before, .testimony-section .owl-nav .owl-prev:focus span:before,
      .testimony-section .owl-nav .owl-next:hover span:before,
      .testimony-section .owl-nav .owl-next:focus span:before {
        color: #000000; }
    .testimony-section .owl-nav .owl-prev {
      left: 50%;
      margin-left: -80px; }
    .testimony-section .owl-nav .owl-next {
      right: 50%;
      margin-right: -80px; }
  .testimony-section:hover .owl-nav .owl-prev,
  .testimony-section:hover .owl-nav .owl-next {
    opacity: 1; }
  .testimony-section:hover .owl-nav .owl-prev {
    left: 50%;
    margin-left: -80px; }
  .testimony-section:hover .owl-nav .owl-next {
    right: 50%;
    margin-right: -80px; }
  .testimony-section .owl-dots {
    text-align: center; }
    .testimony-section .owl-dots .owl-dot {
      width: 10px;
      height: 10px;
      margin: 5px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.2); }
      .testimony-section .owl-dots .owl-dot.active {
        background: #46b7de; }

.testimony-wrap {
  display: block;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  color: rgba(0, 0, 0, 0.8); }
  .testimony-wrap .user-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: relative; }
  .testimony-wrap .text {
    width: calc(100% - 120px);
    position: relative; }
  .testimony-wrap .quote {
    position: absolute;
    top: 0px;
    left: 0; }
    .testimony-wrap .quote i {
      font-size: 24px;
      color: #46b7de; }
  .testimony-wrap .name {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0;
    color: #000000; }
  .testimony-wrap .position {
    font-size: 14px;
    color: #46b7de; }

.image-popup {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1; }

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0; }

#section-counter {
  position: relative;
  z-index: 0; }
  #section-counter:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    z-index: -1;
    opacity: 1;
    background: #46b7de; }

.consultation {
  background: #46b7de;
  color: rgba(255, 255, 255, 0.9);
  padding-top: 4em;
  padding-bottom: 4em; }
  .consultation.consul {
    background: #24a1cd; }
  .consultation h3 {
    font-size: 20px;
    color: #fff;
    font-weight: 500; }
  .consultation .btn-custom {
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600; }

.appointment-form .form-group {
  position: relative;
  width: 100%;
  display: block; }
  .appointment-form .form-group .icon {
    position: absolute;
    top: 50% !important;
    right: 0;
    font-size: 16px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
    .appointment-form .form-group .icon span {
      color: white !important; }
  .appointment-form .form-group .select-wrap, .appointment-form .form-group .input-wrap {
    position: relative; }
    .appointment-form .form-group .select-wrap select, .appointment-form .form-group .input-wrap select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
  .appointment-form .form-group .btn {
    width: 100%;
    display: block !important; }

.appointment-form .form-control {
  border: transparent !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
  height: 40px !important;
  padding-left: 0;
  padding-right: 0;
  background: transparent !important;
  color: rgba(255, 255, 255, 0.9) !important;
  font-size: 13px;
  border-radius: 0px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }
  .appointment-form .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.9); }
  .appointment-form .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.9); }
  .appointment-form .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.9); }
  .appointment-form .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.9); }
  .appointment-form .form-control:focus, .appointment-form .form-control:active {
    border-color: #fff; }

.appointment-form textarea.form-control {
  height: inherit !important; }

.block-23 ul {
  padding: 0; }
  .block-23 ul li, .block-23 ul li > a {
    display: table;
    line-height: 1.5;
    margin-bottom: 15px; }
  .block-23 ul li span {
    color: rgba(255, 255, 255, 0.7); }
  .block-23 ul li .icon, .block-23 ul li .text {
    display: table-cell;
    vertical-align: top; }
  .block-23 ul li .icon {
    width: 40px;
    font-size: 18px;
    padding-top: 2px;
    color: white; }

.contact-section .contact-info p a {
  color: #1a1a1a; }

.contact-section .box {
  width: 100%;
  display: block; }

.block-21 .blog-img {
  display: block;
  height: 80px;
  width: 80px; }

.block-21 .text {
  width: calc(100% - 100px); }
  .block-21 .text .heading {
    font-size: 18px;
    font-weight: 400; }
    .block-21 .text .heading a {
      color: #000000; }
      .block-21 .text .heading a:hover, .block-21 .text .heading a:active, .block-21 .text .heading a:focus {
        color: #46b7de; }
  .block-21 .text .meta > div {
    display: inline-block;
    font-size: 12px;
    margin-right: 5px; }
    .block-21 .text .meta > div a {
      color: gray; }

/* Blog*/

.tagcloud a {
  text-transform: uppercase;
  display: inline-block;
  padding: 4px 10px;
  margin-bottom: 7px;
  margin-right: 4px;
  border-radius: 4px;
  color: #000000;
  border: 1px solid #ccc;
  font-size: 11px; }
  .tagcloud a:hover {
    border: 1px solid #000; }

.comment-form-wrap {
  clear: both; }

.comment-list {
  padding: 0;
  margin: 0; }
  .comment-list .children {
    padding: 50px 0 0 40px;
    margin: 0;
    float: left;
    width: 100%; }
  .comment-list li {
    padding: 0;
    margin: 0 0 30px 0;
    float: left;
    width: 100%;
    clear: both;
    list-style: none; }
    .comment-list li .vcard {
      width: 80px;
      float: left; }
      .comment-list li .vcard img {
        width: 50px;
        border-radius: 50%; }
    .comment-list li .comment-body {
      float: right;
      width: calc(100% - 80px); }
      .comment-list li .comment-body h3 {
        font-size: 18px;
        font-weight: 500; }
      .comment-list li .comment-body .meta {
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: .1em;
        color: #ccc; }
      .comment-list li .comment-body .reply {
        padding: 5px 10px;
        background: #e6e6e6;
        color: #000000;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: .1em;
        font-weight: 400;
        border-radius: 4px; }
        .comment-list li .comment-body .reply:hover {
          color: #fff;
          background: black; }

.search-form {
  background: #fafafa;
  padding: 10px; }
  .search-form .form-group {
    position: relative;
    margin-bottom: 0; }
    .search-form .form-group input {
      padding-right: 50px;
      font-size: 14px; }
  .search-form .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }

.subscribe-form .form-group {
  position: relative;
  margin-bottom: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0; }
  .subscribe-form .form-group input {
    background: transparent !important;
    border: 1px solid transparent;
    color: rgba(255, 255, 255, 0.7) !important;
    font-size: 16px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0; }
    .subscribe-form .form-group input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(255, 255, 255, 0.7) !important; }
    .subscribe-form .form-group input::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(255, 255, 255, 0.7) !important; }
    .subscribe-form .form-group input:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba(255, 255, 255, 0.7) !important; }
    .subscribe-form .form-group input:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(255, 255, 255, 0.7) !important; }
  .subscribe-form .form-group .form-control {
    border: 1px solid rgba(255, 255, 255, 0.4); }
  .subscribe-form .form-group .submit {
    color: #000000 !important;
    display: block;
    width: 100%;
    height: 52px;
    font-size: 16px;
    background: #fff !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0; }
    .subscribe-form .form-group .submit:hover {
      cursor: pointer; }

.subscribe-form .icon {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.8); }

@media (max-width: 991.98px) {
  .sidebar {
    margin-top: 5em; } }

.sidebar-box {
  margin-bottom: 30px;
  padding: 0 25px 25px;
  font-size: 15px;
  width: 100%; }
  .sidebar-box h3 {
    font-size: 18px;
    margin-bottom: 20px; }

.categories {
  padding: 0; }
  .categories li {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dee2e6;
    list-style: none; }
    .categories li:last-child {
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0; }
    .categories li a {
      display: block; }
      .categories li a span {
        position: absolute;
        right: 0;
        top: 0;
        color: #ccc; }
    .categories li.active a {
      color: #000000;
      font-style: italic; }

#ftco-loader {
  position: fixed;
  width: 96px;
  height: 96px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.24);
  border-radius: 16px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .2s ease-out, visibility 0s linear .2s;
  -o-transition: opacity .2s ease-out, visibility 0s linear .2s;
  transition: opacity .2s ease-out, visibility 0s linear .2s;
  z-index: 1000; }

#ftco-loader.fullscreen {
  padding: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  background-color: #fff;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

#ftco-loader.show {
  -webkit-transition: opacity .4s ease-out, visibility 0s linear 0s;
  -o-transition: opacity .4s ease-out, visibility 0s linear 0s;
  transition: opacity .4s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1; }

#ftco-loader .circular {
  -webkit-animation: loader-rotate 2s linear infinite;
  animation: loader-rotate 2s linear infinite;
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  display: block;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

#ftco-loader .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: loader-dash 1.5s ease-in-out infinite;
  animation: loader-dash 1.5s ease-in-out infinite;
  stroke-linecap: round; }

@keyframes loader-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -136px; } }


		@media screen and (max-width: 600px) {
			.container {
				@apply w-[90%] mx-auto
			}
		}