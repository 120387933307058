.slick-arrow {
	display: none;
}

.slick-next {
	display: none;
}

.slick-slider .slick-next {
	display: none;
}
.logo{
	display: flex;
	align-items: center;
	justify-content: center;
}
.logo img{
	width: 150px;
}
.logo a img{
	margin-left: 20px;
}
@media(max-width: 480px){
	.logo a{
		width: 150px;
	}
	.logo a img{
		width: 150px;
	}
	.logo img{
		width: 100px;
	}
}