.footer_copyright{
    width: 100%;
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    background-color: black;
    padding: 20px 50px;
    color: white !important;
}
.footer_copyright h2{
    font-size: 1rem !important;
    color: white !important;
    font-weight: 400;
}
.footer_copyright a{
    border-bottom: 2px solid white;
    color: white !important;
}
@media(max-width:598px){
    .footer_copyright{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}