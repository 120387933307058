.company{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.company a{
    font-weight: 600;
}

/* ACCORDEON */
.home_tour-wrapper {
    /* margin-top: 100px; */
    display: flex;
    min-height: 550px;
    padding: 0;
    overflow: hidden;
    list-style-type: none;
    width: 100%;
    min-width: 100%;
    flex-direction: column;
}

@media only screen and (min-width: 1280px) {
    .home_tour-wrapper {
        flex-direction: row;
    }
}

.home_tour-item {
    flex: 1;
    display: flex;
    align-items: stretch;
    cursor: pointer;
    transition: all 0.35s ease;
    cursor: pointer;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}
.home_tour-block{
    padding: 10px;
    background-color: #3d5b6d;
    color: white;
    font-size: 1.2rem;
    width: 210px;
    margin-top: 20px;
    border-radius: 7px;
    transition: linear all .2s;
}
.home_tour-block:hover{
    transform: scale(1.1);
}
.home_tour-block a{
    color: white;
}
.home_tour-item:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(15, 15, 15, 0.75);
}

.home_tour-item.active {
    flex: 6;
    cursor: default;
}

.home_tour-item.active:before {
    background: linear-gradient(180deg, rgba(15, 15, 15, 0) 0%, #111111 100%);
}

.home_tour-title {
    font-size: 36px;
    line-height: 36px;
    font-weight: 700;
    width: 100%;
    text-transform: uppercase;
}
.bio{
    width: 100%;
}
@media only screen and (min-width: 768px) {
    .home_tour-title {
        font-size: 48px;
        line-height: 48px;
    }
}

@media only screen and (min-width: 1280px) {
    .home_tour-title {
        font-size: 64px;
        line-height: 64px;
    }
}
.home_tour-descr{
    margin-top: 20px;
    margin-bottom: 20px;
}
.home_tour-subtitle {
    font-weight: bold;
    white-space: nowrap;
    position: absolute;
    z-index: 1;
    opacity: 1;
    top: 50%;
    left: 50%;
    transition: top 0.35s, opacity 0.15s;
    transform-origin: 0 0;
    font-size: 24px;
    text-transform: uppercase;
    transform: translate(-50%, -50%) rotate(0deg);
    
}

@media only screen and (min-width: 1280px) {
    .home_tour-subtitle {
        top: 100%;
        left: 50%;
        font-size: 32px;
        transform: translate(-20px, -50%) rotate(-90deg);
    }
}

.active .home_tour-subtitle {
    opacity: 0;
    top: 200%;
}

.section-content {
    position: relative;
    z-index: 1;
    opacity: 0;
    align-self: flex-end;
    width: 100%;
    transition: all 0.35s 0.1s ease-out;
}

.active .section-content {
    opacity: 1;
}

.section-content .inner {
    position: absolute;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
    align-items: flex-end;
    left: 0;
    bottom: 0;
    padding: 20px;
    opacity: 0;
    transition: opacity 0.25s ease-out;
}

@media only screen and (min-width: 768px) {
    .section-content .inner {
        grid-auto-flow: column;
        grid-template-columns: calc(100% - 340px) 300px;
        grid-column-gap: 40px;
        padding: 40px;
    }
}

@media only screen and (min-width: 1280px) {
    .section-content .inner {
        grid-auto-flow: column;
        grid-template-columns: 560px 200px;
        grid-column-gap: 40px;
        padding: 40px;
    }
}

.active .section-content .inner {
    opacity: 1;
}

.artist-profile-link {
    pointer-events: none;
}

.active .artist-profile-link {
    pointer-events: all;
}
