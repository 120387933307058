@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.ttf');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-search:before {
  content: "\f002";
}
.icon-heart:before {
  content: "\f004";
}
.icon-map-marker:before {
  content: "\f041";
}
.icon-calendar:before {
  content: "\f073";
}
.icon-phone:before {
  content: "\f095";
}
.icon-twitter:before {
  content: "\f099";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-google-plus:before {
  content: "\f0d5";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-quote-left:before {
  content: "\f10d";
}
.icon-instagram:before {
  content: "\f16d";
}
.icon-paper-plane:before {
  content: "\f1d8";
}
.icon-map:before {
  content: "\f279";
}
.icon-chat:before {
  content: "\e0b7";
}
.icon-person:before {
  content: "\e7fd";
}
